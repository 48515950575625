import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/system";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#ab6f31",
  fontSize: "20px",
  
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "#ab6f31",
  width: '8%',
  
}));



function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)} className="navbar"
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/">HOME</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/about">ABOUT US</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/cars">CARS</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/contact">CONTACT US</StyledLink>
            </ListItemText>
          </ListItem>
        </List>

       
      </Drawer>
      <StyledIconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </StyledIconButton>
    </>
  );
}

export default DrawerComponent;
