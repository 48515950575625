import React from 'react';
import Footer from './Footer';
import Navbar from '../components/Navbar';

const AboutUs = () => {
  return (
    <>

    <Navbar />
    
    <div className="about-us">
      <h1 style={{textTransform:'uppercase'}}>About Swag Ridez</h1>
      <p>
        At Swag Ridez, we’re passionate about providing you with the finest pre-owned luxury vehicles that embody sophistication and performance. Founded on the principles of quality and customer satisfaction, our mission is to offer a premium car-buying experience. With a team of dedicated experts and a commitment to excellence, we ensure every vehicle meets our high standards. Join us and discover why Swag Ridez is the name you can trust for your next luxury ride.
      </p>

      <div className="about-sections">
        <div className="section">
          <h2>Our Mission</h2>
          <p>
            To offer exceptional pre-owned luxury vehicles with a focus on quality, integrity, and customer satisfaction.
          </p>
        </div>
        <div className="section">
          <h2>Our Team</h2>
          <p>
            Meet the team behind Swag Ridez—experts in luxury cars who are here to guide you through every step of your journey.
          </p>
        </div>
        <div className="section">
          <h2>Our Values</h2>
          <p>
            We value transparency, excellence, and a customer-first approach. At Swag Ridez, your satisfaction is our top priority.
          </p>
        </div>
      </div>
    </div>
    
    <Footer />
    
    </>
  );
};

export default AboutUs;
