import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Cars from './pages/Cars';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Chatbot from './components/floatingButton/Chatbot';
import SpeedDialComponent from './components/floatingButton/SpeedDialComponent';
import FAQ from './pages/FAQ';
import NewsBlogs from './pages/NewsBlogs';




function App() {
  return (
    <React.Fragment>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/newsblogs" element={<NewsBlogs />} />
        

        </Routes>
        <SpeedDialComponent />
    </React.Fragment>
  );
}

export default App;
