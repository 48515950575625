import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import './navbar.css';

const NavLinks = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(18),
  display: "flex",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#ab6f31",
  fontSize: "16px",
  fontWeight:'bold',
  margin: 'auto',
  marginLeft: theme.spacing(5),
  borderBottom: "1px solid transparent",
  "&:hover": {
    color: "#f0f0e3",
    fontSize: "15.5px",
  },
}));

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="sticky" style={{ padding: "10px 10px" }} className="navbar">
      <CssBaseline />
      <Toolbar style={{ justifyContent: "space-between" }}>
        <StyledLink to="/">
          <Stack>
            <img className="Nav-logo" src="./images/swag-logo-001.png" alt="main-logo" />
          </Stack>
        </StyledLink>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <NavLinks>
            <StyledLink to="/" id="heading1" style={{ marginLeft: "30px" }}>
              HOME
            </StyledLink>
            <StyledLink to="/about" id="heading1" style={{ marginLeft: "30px" }}>
              ABOUT US
            </StyledLink>
            <StyledLink to="/cars" id="heading1" style={{ marginLeft: "30px" }}>
              CARS
            </StyledLink>
            <StyledLink to="/contact" id="heading1" style={{ marginLeft: "30px" }}>
              CONTACT
            </StyledLink>
          </NavLinks>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
