import React from 'react';
import Footer from './Footer';
import Navbar from '../components/Navbar';

const TermsAndConditions = () => {
    return (
        <>
        <Navbar />
        
        <div className="about-us">
            <h1 style={{textTransform:'uppercase'}}>Terms and Conditions</h1>
            <p>
                Welcome to Swag Ridez. By using our website and services, you agree to comply with our terms and conditions. Please review them carefully to understand your rights and obligations. If you have any questions, feel free to contact us.
            </p>

            <section>
                <h2>Website Use</h2>
                <p>Use of our website is governed by these terms. Ensure you comply with all applicable laws and regulations.</p>
            </section>

            <section>
                <h2>Purchase Terms</h2>
                <p>Understand the terms for buying vehicles, including payment options, warranties, and return policies.</p>
            </section>

            <section>
                <h2>Liability</h2>
                <p>Swag Ridez is not liable for inaccuracies or errors on the website. Always verify details before making a purchase.</p>
            </section>

            <section>
                <h2>Changes</h2>
                <p>We reserve the right to modify these terms at any time. Your continued use of the site indicates your acceptance of any changes.</p>
            </section>
        </div>
        
        <Footer />
        
        </>
    );
};

export default TermsAndConditions;
