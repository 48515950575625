import React, { useState } from "react";
import "./ProductPage.css";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import { FaInstagramSquare } from "react-icons/fa";

const cars = [
  {
    id: 1,
    name: "MERCEDES BENZ",
    image: "./images/c4.jpg",
    // price: "$625,000",
    // details:
    //   "Stay Connected with Swag Ridez! Join us on social media for the latest updates, exclusive offers and much more .Click the icons below to follow us!",
  },
  {
    id: 2,
    name: "BMW",
    image: "./images/c2.jpg",
    // price: "$517,770",
    // details:
    //   "Stay Connected with Swag Ridez! Join us on social media for the latest updates, exclusive offers and much more .Click the icons below to follow us!",
  },
  {
    id: 3,
    name: "BENTLEY",
    image: "./images/c3.jpg",
    // price: "$203,500",
    // details:
    //   "The Bentley is an iconic sports car known for its speed and precision.",
  },
  {
    id: 4,
    name: "RANGE ROVER",
    image: "./images/c5.jpg",
    // price: "$231,800",
    // details:
    //   "The Bentley Continental GT is a grand tourer with a perfect blend of luxury and power.",
  },
  {
    id: 5,
    name: "AUDI",
    image: "./images/c8.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  {
    id: 6,
    name: "TOYOTA",
    image: "./images/Toyota.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  {
    id: 7,
    name: "VOLVO",
    image: "./images/Volvo.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  {
    id: 8,
    name: "PORSCHE",
    image: "./images/Porsche.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  {
    id: 9,
    name: "JAGUAR",
    image: "./images/Jaguar.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  {
    id: 10,
    name: "FORD",
    image: "./images/Ford.jpg",
    // price: "$205,600",
    // details:
    //   "The Aston Martin DB11 is a luxurious grand tourer with stunning design and performance.",
  },
  
];

const Cars = () => {
  const [selectedCar, setSelectedCar] = useState(null);

  const handleCardClick = (car) => {
    setSelectedCar(car);
  };

  const handleClosePopup = () => {
    setSelectedCar(null);
  };

  return (
    <>
      <Navbar />

      <div className="product-page">
        <h1 style={{ textTransform: "uppercase" }}>Luxury Cars Collection</h1>
        <p style={{ margin: "auto", textAlign: "justify", color: "#000", }}>
          At Swag Ridez, we specialize in offering a curated selection of
          premium luxury cars that cater to the discerning tastes of our
          clients. Our inventory features an array of high-end brands, including
          BMW, Mercedes-Benz, Bentley, Audi, and more. Each vehicle is
          meticulously inspected and refurbished to meet the highest standards
          of quality and performance. Whether you are seeking a sleek sports
          car, an elegant sedan, or a sophisticated SUV, our collection embodies
          the pinnacle of automotive excellence. Our knowledgeable team is
          dedicated to providing personalized service and expert guidance to
          ensure you find the perfect luxury vehicle that matches your lifestyle
          and preferences. At Swag Ridez, we are committed to delivering an
          exceptional car-buying experience and unparalleled satisfaction with
          every purchase.
        </p>
        <div className="product-grid">
          {cars.map((car) => (
            <div
              className="product-card"
              key={car.id}
              onClick={() => handleCardClick(car)}>
              <img src={car.image} alt={car.name} className="product-image" />
              <h2 className="product-name">{car.name}</h2>
              {/* <p className="product-price">{car.price}</p> */}
            </div>
          ))}
        </div>
        <p style={{ margin: "auto", textAlign: "justify", color: "#000" }}>
        For regular updates on our latest luxury cars and exclusive offers, follow us on Instagram and Facebook. Stay connected to see our newest arrivals, special promotions, and more.
        </p>
      </div>

      {selectedCar && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-card" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={handleClosePopup}>
              X
            </button>
            <img
              src={selectedCar.image}
              alt={selectedCar.name}
              className="popup-image"
            />
            <h2 className="popup-name">{selectedCar.name}</h2>
            {/* <p className="popup-price">{selectedCar.price}</p> */}
            <a
                href="https://www.instagram.com/swagridez?igsh=OXIyZWFzYmlnMXgy"
                target="_blank"
                rel="noopener noreferrer"><p className="popup-details">"Stay Connected with Swag Ridez! Join us on social media for the latest updates, exclusive offers and much more .Click the icons below to follow us!",</p></a>
            <a
                href="https://www.instagram.com/swagridez?igsh=OXIyZWFzYmlnMXgy"
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagramSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Cars;
