import React, { useState } from 'react';
import Footer from './Footer';
import Navbar from '../components/Navbar';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      category: "General Information",
      questions: [
        { question: "What is Swag Ridez?", answer: "Swag Ridez is a company that specializes in selling pre-owned luxury cars exclusively in Delhi and Hyderabad." },
        { question: "What types of luxury cars do you offer?", answer: "We offer a wide range of luxury cars from brands like Mercedes-Benz, BMW, Audi, Jaguar, and more." },
        { question: "Are all your cars inspected?", answer: "Yes, all cars undergo a thorough inspection before being listed for sale." },
        { question: "What is the price range of the cars you sell?", answer: "Our cars typically range from ₹5 lakhs to ₹70 lakhs, depending on the make, model, and year." }
      ]
    },
    {
      category: "Purchasing Process",
      questions: [
        { question: "How can I purchase a car from Swag Ridez?", answer: "You can visit our showroom in Delhi, browse our website, or contact our sales team to schedule a test drive and complete the purchase." },
        { question: "Do you offer financing options?", answer: "Yes, we have partnerships with several banks and financial institutions to offer competitive financing options." },
        { question: "Can I trade in my current car when purchasing a luxury car from Swag Ridez?", answer: "Yes, we accept trade-ins. Our experts will evaluate your current car and offer a fair trade-in value." },
        { question: "Can I get a test drive before purchasing?", answer: "Absolutely! We encourage all potential buyers to schedule a test drive before making a purchase." }
      ]
    },
    {
      category: "Services and Support",
      questions: [
        { question: "What kind of inspection do the cars undergo before being sold?", answer: "All cars go through a 150-point inspection covering engine, transmission, suspension, and interior and exterior condition." }
      ]
    },
    {
      category: "Payment and Financing",
      questions: [
        { question: "What payment methods do you accept?", answer: "We accept bank transfers and financing options through our partner banks." },
        { question: "Is there a down payment required for financing?", answer: "Yes, the down payment varies based on the car's price and the chosen financing plan." },
        { question: "Do you offer any discounts or special offers?", answer: "We occasionally offer special promotions. Please check our website and social media pages for current offers." },
        { question: "Can I reserve a car online?", answer: "Yes, you can reserve a car by paying a refundable deposit through bank transfer/Paytm/GPay, etc." },
        { question: "Is there a cancellation policy if I change my mind after reserving a car?", answer: "Yes, you can cancel your reservation within a specified period and receive a full refund of your deposit." }
      ]
    },
    {
      category: "Additional Services",
      questions: [
        { question: "Do you provide car insurance?", answer: "Yes, we offer insurance packages through our partnered insurance companies." },
        { question: "Can I get assistance with car registration?", answer: "Yes, we provide assistance with car registration and all related paperwork." },
        { question: "Do you offer any loyalty programs?", answer: "Yes, we have a loyalty program for repeat customers and referrals, offering discounts on future purchases and services." },
        { question: "Can I sell my luxury car through Swag Ridez?", answer: "Yes, we buy pre-owned luxury cars. Our experts will appraise your car and offer a competitive price." },
        { question: "How often do you update your inventory?", answer: "Our inventory is updated regularly, with new arrivals listed on our website as soon as they are available." }
      ]
    }
  ];

  return (
    <>

    <Navbar />
    
    <div className="faq">
      <h1 style={{margin:'auto',textAlign:'center'}}>Frequently Asked Questions</h1>
      {faqData.map((section, i) => (
        <div key={i} className="faq-section">
          <h2>{section.category}</h2>
          {section.questions.map((item, index) => (
            <div 
              key={index} 
              className={`faq-item ${activeIndex === `${i}-${index}` ? 'active' : ''}`} 
              onClick={() => toggleFAQ(`${i}-${index}`)}
            >
              <div className="faq-question">{item.question}</div>
              <div className="faq-answer">{item.answer}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
    
    <Footer />
    
    </>
  );
};

export default FAQ;
