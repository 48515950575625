import React from 'react';
import Footer from './Footer';
import Navbar from '../components/Navbar';
import { Grid, Typography } from "@material-ui/core";

const ContactUs = () => {
  return (
    <>
    <Navbar />
    
    <Grid container className="about-main-box" id="venue-box"
         style={{backgroundColor:'#000',padding:'20px 10px',borderRadius:'15px',width:'80%', margin:'auto',marginTop:'50px', marginBottom:'100px'}}>
       
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", padding: "5px" }}>
          <Typography style={{ textAlign: "center" }} className="heading-main-box">
          <h2 style={{ margin: "auto", textAlign: "center", color: "#ab6f31" }}>
          Contact Swag Ridez!
        </h2>
        <div className="contact-details">
          <p>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="mailto:swagridez1986@gmail.com">
              <span>Email:{" "}</span>swagridez1986@gmail.com
            </a>
          </p>
          <p>
            <p>
            <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="tel:+91 8766686661">
                <span>Phone:{" "}</span>+91 87 666 8666 1 ,
              </a>{" "}
              
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="tel:+91 8999999950">
                +91 8 99 99 999 50 
              </a>
              
            </p>
          </p>
        </div>
        {/* <p style={{ color: "#ab6f31" }} className="connect-message">
          Connect with us and give your child an unforgettable summer full of
          learning, growth, and fun!
        </p> */}
          </Typography>
          
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div className="profile-wrapper-contact" style={{ margin: "auto" }}>
            <div className="profile-contact">
              <div className="profile-image-contact">
                <img style={{width:'40%',borderRadius:'12px'}} src="./images/c4.jpg" alt="About" />
              </div>
            </div>
          </div>
        </Grid>

        
      </Grid>
    <Footer />
    
    </>
  );
};

export default ContactUs;
