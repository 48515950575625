import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          // backgroundColor: "#000089",
          // background: "rgb(0,15,38)",
          // background: "linear-gradient(319deg, rgba(0,15,38,1) 0%, rgba(115,160,197,1) 96%, rgba(152,221,237,1) 100%)",
          marginTop: "50px",
          margin:'auto',
          
        }}>
        <Grid container className="footer-main-box">
          <Grid
            item
            className="footer-box1"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <img src="./images/swag-logo-001.png" />
          </Grid>

          <Grid
            item
            className="footer-box2"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#ab6f31" }}>Short Link</h2>
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/about">
              <p>About Us</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/contact">
              <p>Contact Us</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/newsblogs">
              <p>News Blogs</p>
            </Link>

            {/* <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/cars">
              <p>Cars</p>
            </Link> */}

            
          </Grid>

          <Grid
            item
            className="footer-box3"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#ab6f31" }}>Help Link</h2>
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/termsandconditions">
              <p>Terms and Conditions</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/privacypolicy">
              <p>Privacy Policy</p>
            </Link>

            

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/faq">
              <p>FAQ'S</p>
            </Link>
          </Grid>

          <Grid
            item
            className="footer-box3"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#ab6f31" }}>Follow Us</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://www.instagram.com/swagridez?igsh=OXIyZWFzYmlnMXgy"
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagramSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://www.facebook.com/share/ECTxKXon8keW46ry/?mibextid=qi2Omg"
                target="_blank"
                rel="noopener noreferrer">
                <FaFacebookSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://www.youtube.com/@swagridez"
                target="_blank"
                rel="noopener noreferrer">
                <FaYoutubeSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://api.whatsapp.com/send?phone=918999999950"
                target="_blank"
                rel="noopener noreferrer">
                <FaWhatsappSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a>

              {/* <a
                href="https://twitter.com/learnorama_in"
                target="_blank"
                rel="noopener noreferrer">
                <FaTwitterSquare
                  style={{ fontSize: 30, color: "#ab6f31", margin: "0 10px" }}
                />
              </a> */}
            </div>
            <p
          style={{
            background: "#000",
            margin:'auto',
            textAlign:'center',
            alignItems:'center'
            
          }}>
            {/* #ab6f31 */}
          &copy; 2024 <span style={{color:'#ab6f31'}}>Swag Ridez.</span> All rights reserved. Designed, developed, and AI marketing by <span style={{color:'#ab6f31'}}>Learnorama Technologies Private Limited.</span>
        </p>
          </Grid>
          
        </Grid>

        
      </div>
    </>
  );
};

export default Footer;
