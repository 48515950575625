import React from 'react';
import '../style.css';
import Navbar from '../components/Navbar';
import Footer from './Footer';

const Home = () => {
  return (
    <>
    <Navbar />
    
    <div className="home">
      <h1 style={{textTransform:'uppercase'}}>Welcome to Swag Ridez!</h1>
      <p>
        Experience the ultimate in style and performance with Swag Ridez, where luxury meets affordability. Our curated collection of high-end pre-owned vehicles is designed to turn heads and deliver unparalleled driving pleasure. Explore our stunning inventory, discover exclusive deals, and find your next dream ride today. Dive into the world of swag and sophistication—your journey starts here!
      </p>

      

      <div className="park-and-sell">
      <h1>Park & Sell Your Car at Swag Ridez</h1>
      <section className="how-it-works">
        <h2>How It Works:</h2>
        <ol>
          <li>
            <strong>Drop Off Your Car:</strong> Bring your car to our showroom, and we'll take care of the rest.
          </li>
          <li>
            <strong>Vehicle Assessment:</strong> Our experts will assess your car to determine its market value and help set a competitive price.
          </li>
          <li>
            <strong>Professional Marketing:</strong> We will professionally photograph and list your vehicle on our website and other platforms, reaching potential buyers quickly.
          </li>
          <li>
            <strong>Sales Process:</strong> Our team will manage inquiries, negotiate offers, and handle test drives with interested buyers.
          </li>
          <li>
            <strong>Hassle-Free Formalities:</strong> From paperwork to final payment, we manage all the formalities, ensuring a smooth transaction.
          </li>
          <li>
            <strong>Commission-Based Service:</strong> Once your car is sold, we take a commission from the sale, and the rest of the money goes to you.
          </li>
        </ol>
      </section>
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Trusted Platform:</strong> Swag Ridez is a trusted name in luxury car sales, ensuring your vehicle is in good hands.</li>
          <li><strong>Experienced Team:</strong> Our team has years of experience in the industry, providing top-notch service to both sellers and buyers.</li>
          <li><strong>Maximum Exposure:</strong> We market your vehicle to a wide audience, increasing the chances of a quick and profitable sale.</li>
        </ul>
      </section>
      <section className="get-started">
        <h2>Get Started Today!</h2>
        <p>Contact us to schedule your car drop-off or learn more about our Park & Sell service.</p>
      </section>
    </div>

    <div className="home-sections">
        <div className="section">
          <h2>Featured Vehicles</h2>
          <p>
            Check out our top picks! From sleek sedans to powerful SUVs, our featured vehicles offer the best in luxury and performance.
          </p>
        </div>
        
        <div className="section">
          <h2>Customer Testimonials</h2>
          <p>
            Don’t just take our word for it—see what our satisfied customers have to say about their Swag Ridez experience!
          </p>
        </div>
        <div className="section">
          <h2>Call to Action</h2>
          <p>
            Ready to ride in style? Browse our collection now or schedule a test drive today!
          </p>
        </div>
      </div>

    </div>

    <Footer />
    
    </>
  );
};

export default Home;
