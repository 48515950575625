import React from 'react';
import Navbar from '../components/Navbar';
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <>

        <Navbar />
        
        <div className="about-us">
            <h1 style={{textTransform:'uppercase'}}>Privacy Policy</h1>
            <p>
                At Swag Ridez, your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information. We are committed to safeguarding your data and ensuring your privacy while you use our website.
            </p>

            <section>
                <h2>Data Collection</h2>
                <p>We collect personal information such as contact details and vehicle preferences to enhance your experience.</p>
            </section>

            <section>
                <h2>Usage</h2>
                <p>Your information is used to provide you with tailored services, process transactions, and improve our offerings.</p>
            </section>

            <section>
                <h2>Protection</h2>
                <p>We implement robust security measures to protect your data from unauthorized access and misuse.</p>
            </section>

            <section>
                <h2>Cookies</h2>
                <p>We use cookies to improve your browsing experience. You can manage your cookie preferences through your browser settings.</p>
            </section>

            <section>
                <h2>User Rights</h2>
                <p>You have the right to access, correct, or delete your personal information. Contact us if you wish to exercise these rights.</p>
            </section>
        </div>
        
        <Footer />
        
        </>


    );
};

export default PrivacyPolicy;
