import React, { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const chatbotQuestions = [
  {
    question: "What is Swag Ridez?",
    answer: "Swag Ridez is a company that specializes in selling pre-owned luxury cars exclusively in Delhi and Hyderabad."
  },
  {
    question: "What types of luxury cars do you offer?",
    answer: "We offer a wide range of luxury cars from brands like Mercedes-Benz, BMW, Audi, Jaguar, and more."
  },
  {
    question: "Are all your cars inspected?",
    answer: "Yes, all cars undergo a thorough inspection before being listed for sale."
  },
  {
    question: "What is the price range of the cars you sell?",
    answer: "Our cars typically range from ₹5 lakhs to ₹70 lakhs, depending on the make, model, and year."
  },
  {
    question: "How can I purchase a car from Swag Ridez?",
    answer: "You can visit our showroom in Delhi, browse our website, or contact our sales team to schedule a test drive and complete the purchase."
  },
  {
    question: "Do you offer financing options?",
    answer: "Yes, we have partnerships with several banks and financial institutions to offer competitive financing options."
  },
  {
    question: "Can I trade in my current car when purchasing a luxury car from Swag Ridez?",
    answer: "Yes, we accept trade-ins. Our experts will evaluate your current car and offer a fair trade-in value."
  },
  {
    question: "Can I get a test drive before purchasing?",
    answer: "Absolutely! We encourage all potential buyers to schedule a test drive before making a purchase."
  },
  {
    question: "What kind of inspection do the cars undergo before being sold?",
    answer: "All cars go through a 150-point inspection covering engine, transmission, suspension, and interior and exterior condition."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept bank transfers and financing options through our partner banks."
  },
  {
    question: "Is there a down payment required for financing?",
    answer: "Yes, the down payment varies based on the car's price and the chosen financing plan."
  },
  {
    question: "Do you offer any discounts or special offers?",
    answer: "We occasionally offer special promotions. Please check our website & social media pages for current offers."
  },
  {
    question: "Can I reserve a car online?",
    answer: "Yes, you can reserve a car by paying a refundable deposit through bank transfer, Paytm, GPay, etc."
  },
  {
    question: "Is there a cancellation policy if I change my mind after reserving a car?",
    answer: "Yes, you can cancel your reservation within a specified period and receive a full refund of your deposit."
  },
  {
    question: "Do you provide car insurance?",
    answer: "Yes, we offer insurance packages through our partnered insurance companies."
  },
  {
    question: "Can I get assistance with car registration?",
    answer: "Yes, we provide assistance with car registration and all related paperwork."
  },
  {
    question: "Do you offer any loyalty programs?",
    answer: "Yes, we have a loyalty program for repeat customers and referrals, offering discounts on future purchases and services."
  },
  {
    question: "Can I sell my luxury car through Swag Ridez?",
    answer: "Yes, we buy pre-owned luxury cars. Our experts will appraise your car and offer a competitive price."
  },
  {
    question: "How often do you update your inventory?",
    answer: "Our inventory is updated regularly, with new arrivals listed on our website as soon as they are available."
  }
];


const Chatbot = ({ isOpen, onClose }) => {
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [activeTextIndex, setActiveTextIndex] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synth] = useState(window.speechSynthesis);

  const handleQuestionClick = (questionId) => {
    setActiveQuestionId(questionId);
    setActiveTextIndex(null); // Reset active text when a new question is selected
  };

  const handleTextClick = (textIndex) => {
    setActiveTextIndex((prevIndex) => (prevIndex === textIndex ? null : textIndex));
  };

  const speakText = (text) => {
    if (!synth) return;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
    setIsSpeaking(true);

    utterance.onend = () => setIsSpeaking(false);
  };

  const handleSpeakClick = (text) => {
    speakText(text);
  };

  const handleMuteClick = () => {
    synth.cancel();
    setIsSpeaking(false);
  };

  const handleBackClick = () => {
    setActiveTextIndex(null);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        width: 300,
        backgroundColor: "#fff",
        boxShadow: 3,
        borderRadius: 2,
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        height: 400,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 1,
          borderBottom: "1px solid #ddd",
          backgroundColor: "#f4f4f4",
        }}
      >
        {activeQuestionId !== null && activeTextIndex === null ? (
          <IconButton onClick={() => setActiveQuestionId(null)}>
            <ArrowBackIcon />
          </IconButton>
        ) : activeTextIndex !== null ? (
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        <Box sx={{ flexGrow: 1, fontWeight: "bold", marginLeft: '5px' }}>
          Chatbot
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
        {activeQuestionId === null ? (
          <>
            <p>Frequently Asked Questions (FAQs) for Swag Ridez</p>
            <List>
              {chatbotQuestions.map((question, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => setActiveQuestionId(index)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="primary">
                        {question.question}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Box>
            <Typography variant="body1" color="secondary">
              {chatbotQuestions[activeQuestionId].answer}
            </Typography>
            <Button
              startIcon={isSpeaking ? <VolumeOffIcon /> : <VolumeUpIcon />}
              onClick={() =>
                isSpeaking
                  ? handleMuteClick()
                  : handleSpeakClick(chatbotQuestions[activeQuestionId].answer)
              }
            >
              {isSpeaking ? "Stop" : "Speak"}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
  
  
};

export default Chatbot;